import { createRouter, createWebHistory } from 'vue-router'
import { getUserFromLocal } from '../utils/local'

const routes = [
  {
    path: '/',
    redirect: '/operasi-jadwal-display',
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/operasi-jadwal-display",
    name: "JadwalOperasiPlainView",
    component: () => import("../views/JadwalOperasiPlainView.vue"),
  },
  {
    path: "/operasi-jadwal",
    name: "JadwalOperasiView",
    component: () => import("../views/JadwalOperasiView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/operasi-jadwal-detail",
    name: "DetailPasienView",
    component: () => import("../views/DetailPasienView.vue"),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = getUserFromLocal(); // TODO: check if user is authenticated
  if (to.meta.requiresAuth && !isAuthenticated) {
    next("/login");
  } else if (to.path === "/login" && isAuthenticated) {
    next("/operasi-jadwal");
  } else {
    next();
  }
});

export default router
