import axios from "axios";

const localApiUrl = "http://192.168.0.101:10002";
const nginxApiUrl = 'https://jadwal-operasi.rsuaminahblitar.com/jadwal-operasi-api/';

const hostname = window.location.hostname;
const hostnamePartials = hostname.split('.');
const accessFromLocal = hostnamePartials[2] == 0;

// Development
// axios.defaults.baseURL = localApiUrl;

// Production
// NGINX
axios.defaults.baseURL = accessFromLocal ? localApiUrl : nginxApiUrl;

export default axios;
