import { createStore } from 'vuex'
import persistedStateVuex from "persisted-state-vuex";

export default createStore({
  plugins: [persistedStateVuex.init],
  state: {
    data: null,
    schedule: null
  },
  getters: {
    getData: (state) => state.data,
    getSchedule: (state) => state.schedule,
  },
  mutations: {
    setData(state, newData) {
      state.data = newData;
    },
    setSchedule(state, newSchedule) {
      state.schedule = newSchedule;
    },
  },
  actions: {
    updateData({ commit }, newData) {
      commit('setData', newData);
    },
    updateSchedule({ commit }, newSchedule) {
      commit('setSchedule', newSchedule);
    },
  },
  modules: {
  }
})
